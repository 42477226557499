import { Component, OnInit } from '@angular/core';
import { DatosService } from '../../services/datos.service';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute} from '@angular/router';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-trabajo',
  templateUrl: './trabajo.component.html'
})
export class TrabajoComponent implements OnInit {
  vistaApartado:boolean = false;
  categorias:any[];
  trabajo: any[];
  productos: any[];
  trabajosFiltrados: any[];
  trabajoIndice:number;
  trabajoAnterior: any[];
  trabajoSiguiente: any[];

  espera: number = 6000;
  indexActual:number;
  interval;
  active: boolean = false;
  ampliacion: boolean = false;

  galeria:any[];

  constructor(private _datosService:DatosService, private activatedRoute:ActivatedRoute, private router: Router, private meta:Meta) {
  }

  ngOnInit() {
    this.activatedRoute.url.subscribe( url=> {
      let ruta = this.activatedRoute.snapshot.url[0].path;
      let rutaProducto = this.activatedRoute.snapshot.url[1].path;
      this._datosService.ponSeccion(ruta);
      this.ampliacion = false;

      this._datosService.getCategorias().subscribe(datos => {
        this.categorias = datos;
        if(this.activatedRoute.snapshot.url.length > 1) {
          this._datosService.getProducto(rutaProducto).subscribe( datos => {
              this.trabajo = datos;
              this.trabajo['miNombre'] = this.trabajo[this._datosService.getCampo('nombre')];
              this.trabajo['miDescripcion'] = this.trabajo[this._datosService.getCampo('descripcion')];
              this.trabajo['miCategoria'] = this.getCategoria(this.trabajo['id_categoria']);

              console.log(this.trabajo);
              console.log(this.trabajo['galeria']);


              //ORDENO POR POSITION
              this.trabajo['galeria'].sort((val1, val2)=> {return val2.position - val1.position});

              //saco del objeto galeria las entradas
              let galeria = this.trabajo['galeria'].map((foto) => foto.imagenes);
              console.log(this.trabajo['galeria'][0].id);

              let arrGaleria = Object.keys(galeria).map(function(foto){
                let laFoto = galeria[foto][0];
                return laFoto;
              });




              //filtro que en imagenes solo se quede las que sea propietario productos...
              this.trabajo['imagenes'] = this.trabajo['imagenes'].filter((imagen)=> imagen.owner_type == "productos");
              //concateno array imagenes con array galeria ya limpio
              this.galeria = this.trabajo['imagenes'].concat(arrGaleria);
              //this.galeria = this.galeria.map((foto) => foto.path );

              this.galeria = this.galeria.map( function (foto) {
                return foto.path;
              });

              console.log(this.galeria);

              this.indexActual = 0;
              //setTimeout(() => { this.active = true; this.getNext(); }, 500);


              this.meta.updateTag({ name: 'title', content: 'Shar Arquitectura | ' + this._datosService.getCampo('nombre')});
              this.meta.updateTag({ name: 'description', content: this.trabajo['meta_descripcion']});



                this._datosService.getProductos().subscribe(datos => {
                  this.productos = datos;
                  if(this._datosService.categoriaFiltrada != '') {
                    this.trabajosFiltrados = this.productos.filter((producto)=> producto.id_categoria == this._datosService.categoriaFiltrada );
                  }
                  else {
                    this.trabajosFiltrados = this.productos;
                  }

                  //saber el indice del proyecto actual...
                  this.trabajoIndice = this.trabajosFiltrados.findIndex(item=> item['id'] === this.trabajo['id']);
                  //SACAMOS ANTERIOR Y SIGUIENTE
                  if(this.trabajosFiltrados.length > 1) {
                    if(this.trabajoIndice == 0) {
                        this.trabajoAnterior = this.trabajosFiltrados[this.trabajo.length - 1];
                    }
                    else {
                      this.trabajoAnterior = this.trabajosFiltrados[this.trabajoIndice - 1];
                    }

                    if(this.trabajoIndice == this.trabajosFiltrados.length - 1) {
                        this.trabajoSiguiente = this.trabajosFiltrados[0];
                    }
                    else {
                      this.trabajoSiguiente = this.trabajosFiltrados[this.trabajoIndice + 1];
                    }
                  }

                });
                this.sacaApartado();
          });
        }
      });
    });
  }

  getCategoria(id) {
    let miCategoria = this.categorias.findIndex((categoria) => categoria.id == id );
    return (this.categorias[miCategoria][this._datosService.getCampo('nombre')]);
  }

  getCampo(dato) {
    return (this.trabajo[this._datosService.getCampo(dato)]);
  }

  sacaApartado() {
    setTimeout(() => {
      this.vistaApartado = true;
    }, 100);
  }

  verProyecto(url) {
  //this.apartadoAbierto = false;
    this.router.navigate( ['/proyecto', url]);
  }

  anterior() {
    if(this.indexActual == 0) {
      this.indexActual = this.galeria.length - 1;
    }
    else {
      this.indexActual--;
    }
  }
  siguiente() {
    if(this.indexActual == this.galeria.length - 1) {
      this.indexActual = 0;
    }
    else {
      this.indexActual++;
    }
  }

  getNext() {
    this.interval = setInterval (() => {
        if(this.indexActual == this.galeria.length - 1) {
          this.indexActual = 0;
        }
        else {
          this.indexActual++;
        }
        //this.foto = this.trabajo['imagenes'][this.indexActual];
    }, this.espera);
  }

  ponFoto(esta) {
    clearInterval(this.interval);
    this.indexActual = esta;
    //setTimeout(() => { this.active = true; this.getNext(); }, 500);
  }

  hayFotos() {
    if(this.galeria.length > 2) {
      return true;
    }
  }

}
