import { Routes, RouterModule } from '@angular/router';

import { HomeComponent} from '../app/components/home/home.component';
import { DinamicaComponent} from '../app/components/dinamica/dinamica.component';
import { NosotrosComponent}  from "../app/components/nosotros/nosotros.component";
import { TrabajosComponent}  from "../app/components/trabajos/trabajos.component";
import { TrabajoComponent}  from "../app/components/trabajo/trabajo.component";
import { BlogComponent}  from "../app/components/blog/blog.component";
import { ContactoComponent}  from "../app/components/contacto/contacto.component";
import { AuthGuard } from '../app/guards/auth.guards';


const app_routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'nosotros', component: NosotrosComponent },
  { path: 'proyectos', component: TrabajosComponent },
  //{ path: 'proyectos', pathMatch: 'full', redirectTo: 'proyectos/asistencial' },

  { path: 'proyectos/:id', component: TrabajosComponent },
  { path: 'proyecto/:id', component: TrabajoComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'blog/:id', component: BlogComponent },
  { path: 'aviso-legal', component: DinamicaComponent },
  { path: 'politica-de-privacidad', component: DinamicaComponent },
  { path: 'politica-de-cookies', component: DinamicaComponent },
  { path: 'contacto', component:  ContactoComponent },



  { path: '**', pathMatch: 'full', redirectTo: 'home' }
];


export const app_routing = RouterModule.forRoot(app_routes);
