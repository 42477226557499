import { Component, OnInit } from '@angular/core';
import { DatosService } from '../../services/datos.service';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute} from '@angular/router';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-trabajos',
  templateUrl: './trabajos.component.html'
})
export class TrabajosComponent implements OnInit {
  vistaApartado:boolean = false;
  private productos: any[];
  private productosFiltrados: any[];
  private categorias:any[];
  private categoria:any[];
  private idCategoria: string;
  public nombreProducto: string;


  constructor(private _datosService:DatosService, private activatedRoute:ActivatedRoute, private router: Router, private meta:Meta) {
  }

  ngOnInit() {

    this.activatedRoute.url.subscribe( url=> {
      let ruta = this.activatedRoute.snapshot.url[0].path;
      this._datosService.ponSeccion(ruta);
      console.log(ruta);

      this._datosService.getCategorias().subscribe(datos => {
        this.categorias = datos;
        this._datosService.getProductos().subscribe(datos => {
          this.productos = datos;
          this.productos = this.productos.filter((producto)=> producto.publicado);

          //SI ESTAMOS EN UNA CATEGORIA...
          if(this.activatedRoute.snapshot.url.length > 1) {
            this.productosFiltrados = this.productos.filter((producto)=> this._datosService.checkCategoria(producto.id_categoria) );
          }
          else {

            this.router.navigate(['/proyectos/asistencial']);
            this._datosService.saveCategoria(this.categorias[0]);
            this.productosFiltrados = this.productos;
          }

          //filtro que en imagenes solo se quede las que sea propietario productos...
          Object.entries(this.productosFiltrados).forEach(([key, value]) => {
            this.productosFiltrados[key]['imagenes'] = this.productosFiltrados[key]['imagenes'].filter((imagen)=> imagen.owner_type == "productos");
          });
          console.log(this.productosFiltrados);

          //ORDENO POR POSITION
          this.productosFiltrados.sort((val1, val2)=> {return val2.position - val1.position});

          this.sacaApartado();
        });
      });
    });

  }

  getCategoria(id) {
    let miCategoria = this.categorias.findIndex((categoria) => categoria.id == id );

    return (this.categorias[miCategoria][this._datosService.getCampo('nombre')]);

  }

  sacaApartado() {
  setTimeout(() => {
    this.vistaApartado = true;
  }, 100);
}


}
