import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { DatosService } from '../../services/datos.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
  vistaApartado:boolean = false;
  active: boolean = false;

  categorias:any = [];
  productos:any = [];
  destacadas:any = [];
  trabajo:any= [];

  espera: number = 6000;
  indexActual:number;
  interval;


  constructor(private _datosService:DatosService) { }


  ngOnInit() {
    this._datosService.getCategorias().subscribe(datos => {
      this.categorias = datos;
      this._datosService.getProductos().subscribe(datos => {
        this.productos = datos;
        this.destacadas = this.productos.filter((producto)=> producto.es_novedad);
        this.indexActual = 0;
        this.trabajo = this.destacadas[this.indexActual];
        setTimeout(() => { this.active = true; this.getNext(); }, 500);

        this.vistaApartado = false;
        this.sacaApartado();
      });
    });


    this._datosService.ponSeccion('home');


  }


//FUNCIONES DE SLIDE DE HOME
getNext() {
  this.interval = setInterval (() => {
      if(this.indexActual == this.destacadas.length - 1) {
        this.indexActual = 0;
      }
      else {
        this.indexActual++;
      }
      this.trabajo = this.destacadas[this.indexActual];
  }, this.espera);
}

ponFoto(esta) {
  clearInterval(this.interval);
  this.indexActual = esta;
  this.trabajo = this.destacadas[this.indexActual];
  //setTimeout(() => { this.active = true; this.getNext(); }, 500);
}

getCategoria(id) {
  let miCategoria = this.categorias.findIndex((categoria) => categoria.id == id );

  return (this.categorias[miCategoria][this._datosService.getCampo('nombre')]);

}


  sacaApartado() {
    setTimeout(() => {
      this.vistaApartado = true;
    }, 100);
  }


}
