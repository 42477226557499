import { Component, OnInit } from '@angular/core';
import { DatosService } from '../../services/datos.service';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html'
})
export class CookiesComponent implements OnInit {


    constructor( private _datosService:DatosService) { }

    ngOnInit() {
    }

  }
