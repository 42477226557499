import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatButtonModule, MatCheckboxModule, MatToolbarModule, MatCardModule,
  MatExpansionModule, MatDatepickerModule, MatInputModule, MatTabsModule,
  MatTooltipModule, MatFormFieldModule, MatMenuModule, MatSnackBarModule, MatSelectModule, MatRadioModule, MatListModule } from '@angular/material';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { Http, HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateStaticLoader} from 'ng2-translate';
import { LocationStrategy, HashLocationStrategy, DatePipe, registerLocaleData } from '@angular/common';



import { AppComponent } from './app.component';

// Rutas
import { app_routing } from './app.routes';

import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { AvisoComponent } from './components/aviso/aviso.component';
import { DinamicaComponent } from './components/dinamica/dinamica.component';
import { ContactoComponent } from './components/contacto/contacto.component';
import { FormatocomasPipe } from './pipes/formatocomas.pipe';



import { AuthGuard } from './guards/auth.guards';
import { JwtInterceptorProvider } from './helpers/jwt.interceptor';
import { ErrorInterceptorProvider } from './helpers/error.interceptor';
import { AlertService } from './services/alert.service';
import { CookiesComponent } from './components/cookies/cookies.component';
import { TrabajosComponent } from './components/trabajos/trabajos.component';
import { NosotrosComponent } from './components/nosotros/nosotros.component';
import { FooterComponent } from './components/footer/footer.component';
import { TrabajoComponent } from './components/trabajo/trabajo.component';
import { BlogComponent } from './components/blog/blog.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    AvisoComponent,
    DinamicaComponent,
    ContactoComponent,
    FormatocomasPipe,
    CookiesComponent,
    TrabajosComponent,
    NosotrosComponent,
    FooterComponent,
    TrabajoComponent,
    BlogComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule, // new modules added here
    [MatButtonModule, MatCheckboxModule, MatToolbarModule, MatCardModule, MatMenuModule,
    MatExpansionModule, MatInputModule, MatTabsModule, MatTooltipModule, MatFormFieldModule,
    MatSnackBarModule, MatSelectModule, MatRadioModule, MatListModule],
    HttpModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    app_routing,
    TranslateModule.forRoot({
      provide: TranslateLoader,
      useFactory: function(http: Http) {return new TranslateStaticLoader(http, '/assets/i18n', '.json')},
      deps: [Http]
    }),
  ],
  providers: [
    //{ provide: LocationStrategy, useClass: HashLocationStrategy },
    JwtInterceptorProvider,
    ErrorInterceptorProvider,
    AuthGuard,
    AlertService
    //{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})

export class AppModule{ }
